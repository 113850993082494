
.App {
  height: 100%;
  width: 98vw;
  margin: 0;
  padding: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Sidebar {
  height: 100vh;
  width: 210px;
  background-color: #0165A1;
  padding-top: 72px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sidebarul {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
}

.sidebarli {
  width: 100%;
  height: 50px;
  list-style-type: none;
  display: flex;
  margin: 0px;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  cursor: pointer;
}

.sidebarli:hover {
  background-color: #1976D2;
}

.sidebarul #active {
  background-color: #1976D2;
}

.sideicon {
  flex: 25%;
  display: grid;
  place-items: center;
}

.sidetitle {
  flex: 75%;
}

.PageFit {
  height: 100%;
  overflow-y: auto;
  padding: 1rem;

}

.aifield {
  margin-top: 8% !important;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid white !important;
}

.hometextfield:placeholder {
  color: white;
}

.hometextfield {
  width: 70% !important;
  border: 1px solid white !important;
  border-radius: 5px;
}

.hometextfield:hover {
  border: 1px solid white;
  border-radius: 5px;
}
.attendeaseButton{
  background-color: #0165A1 !important;
   color:#fff !important;
}
.css-15a7htc-MuiPaper-root-MuiCard-root{
  background-color: #0165A1 !important;
  border-bottom: 2px solid #0165A1 !important;
  height: 70px;
}