body{
    margin:0px;
    padding: 0px;
}
.login-container {
    display: flex;
    height: 100vh;
    overflow-y: hidden;
  }

  .login-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
  }

  .login-illustration img {
    max-width: 100%;
    height: auto;
  }

  .login-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
  }

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  .formLogin {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }

  input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .recovery-password {
    align-self: flex-end;
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
  }

  .sign-in-button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #0165A1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .alternative-sign-in {
    margin-top: 20px;
    text-align: center;
  }

  .social-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .social-buttons button {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

